import React from 'react';
import PropTypes from 'prop-types';

const GroupNode = ({ data, id }) => {
  const { label, children } = data;

  return (
    <div
      style={{
        border: '1px solid gray',
        padding: '30px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9', // Ensure consistent background
        width: '100%',
        height: '100%',
      }}
    >
      <h4>{label}</h4>
      {children && children.length > 0 && (
        <div>
          <h5>Children:</h5>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {children.map((child) => (
              <li
                key={child.node_id}
                style={{
                  margin: '5px 0',
                  padding: '5px',
                  border: '1px solid lightgray',
                  borderRadius: '5px',
                  backgroundColor: '#fff',
                }}
              >
                <strong>{child.node_data.label}</strong>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

GroupNode.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    children: PropTypes.array,
  }).isRequired,
  id: PropTypes.string.isRequired,
};

export default GroupNode;
