import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
} from 'reactflow';
import 'reactflow/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFlowById, createFlow, updateFlow, clearCurrentFlow } from '../../../../redux/slices/flowsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// import StartNode from './node_types/StartNode';
import StandardNode from './node_types/StandardNode';
import DecisionNode from './node_types/DecisionNode';
import WorkflowNode from './node_types/WorkflowNode';
import AsideComponent from './aside';

import DynamicForm from './DynamicForm';
import LoopbackEdge from './edge_types/loopback';

const proOptions = { hideAttribution: true };

const nodeTypes = {
  sql: StandardNode,
  bash: StandardNode,
  sap_abap_run: StandardNode,
  sendMail: StandardNode,
  decision: DecisionNode,
  tableRead: StandardNode,
  rest: StandardNode,
  workflow: WorkflowNode,
  workflowCreate: StandardNode,
  workflowActionCreate: StandardNode
};

const getNodeType = (type) => {
  return nodeTypes[type] || StandardNode; // Fallback to StandardNode
};

const Sidebar = ({ onDragStart, parameters, setParameters, flow_id }) => (
  <AsideComponent onDragStart={onDragStart} parameters={parameters} setParameters={setParameters} flow_id={flow_id} />
);

const getNodeVariableString = (nodeId, variable) => {
  return `\${${nodeId}:${variable}}`;
};







const edgeTypes = { loopback: LoopbackEdge };

const FlowEditor = () => {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentFlow = useSelector((state) => state.flows.currentFlow);
  const loading = useSelector((state) => state.flows.loading);

  const [flowName, setFlowName] = useState(''); // State for flow name
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isNewFlow, setIsNewFlow] = useState(false);
  const [isVariableMode, setIsVariableMode] = useState(false); // Variable mode state
  const [parameters, setParameters] = useState([]); // State for parameters
  const [isDataLoading, setIsDataLoading] = useState(true); // Loading state for data
  const idCounter = useRef(0); // Counter to generate sequential IDs

  useEffect(() => {
    const fetchData = async () => {
      if (itemId) {
        await dispatch(fetchFlowById(Number(itemId)));
      } else {
        dispatch(clearCurrentFlow()); // Clear the current flow state when creating a new flow
        setIsNewFlow(true);
      }
      setIsDataLoading(false); // Data is loaded
    };

    fetchData();
    console.log("flow_edges:  " + JSON.stringify(edges));
  }, [dispatch, itemId]);

  useEffect(() => {
    if (currentFlow && !isNewFlow) {
      const { flow_name, flow_nodes, flow_edges, flow_parameters } = currentFlow;
      setFlowName(flow_name || ''); // Set the flow name
      setNodes(Array.isArray(flow_nodes) ? flow_nodes : []);
      setEdges(Array.isArray(flow_edges) ? flow_edges : []);
      setParameters(flow_parameters || []); // Set the parameters

      // Determine the highest node ID and initialize the counter
      const maxId = (Array.isArray(flow_nodes) ? flow_nodes : []).reduce((max, node) => {
        const idNum = parseInt(node.id.replace('node-', ''), 10);
        return idNum > max ? idNum : max;
      }, 0);
      idCounter.current = maxId + 1;
    } else {
      // Reset the state for a new flow
      setFlowName('');
      setNodes([]);
      setEdges([]);
      setParameters([]);
      idCounter.current = 0;
    }
    console.log("flow_edges:  " + JSON.stringify(edges));
  }, [currentFlow, isNewFlow]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const cleanEdgeId = (edgeId) => {
    return edgeId.startsWith('reactflow__edge-') ? edgeId.replace('reactflow__edge-', '') : edgeId;
  };
  

  const onNodeDoubleClick = (event, node) => {
    setSelectedNode(node);
    setShowSidebar(true); // Show the right sidebar
  };

  const handleCloseSidebar = () => setShowSidebar(false);

  const addCondition = () => {
    setSelectedNode((prevNode) => {
      const newCondition = { key: `key${prevNode.data.conditions.length + 1}`, value: '' };
      const updatedConditions = [...prevNode.data.conditions, newCondition];
      return {
        ...prevNode,
        data: { ...prevNode.data, conditions: updatedConditions },
      };
    });
  };

  const handleInputChange = (field, value, conditionIndex = null) => {
    setSelectedNode((prevNode) => {
      const newConditions = [...prevNode.data.conditions];
      if (conditionIndex !== null) {
        newConditions[conditionIndex] = { ...newConditions[conditionIndex], [field]: value };
      }
      return {
        ...prevNode,
        data: {
          ...prevNode.data,
          conditions: newConditions,
          [field]: conditionIndex === null ? value : prevNode.data[field], // Update the field if it's not part of conditions
        },
      };
    });
  };

  const removeCondition = (conditionIndex) => {
    setSelectedNode((prevNode) => {
      const updatedConditions = prevNode.data.conditions.filter((_, index) => index !== conditionIndex);
      return {
        ...prevNode,
        data: { ...prevNode.data, conditions: updatedConditions },
      };
    });
  };

  const handleSave = () => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === selectedNode.id ? { ...node, data: { ...selectedNode.data } } : node
      )
    );
    // setShowSidebar(false); // Close the sidebar after saving
  };

  const reactFlowInstance = useReactFlow();

  const onConnect = useCallback(
    (params) => {
      const sourceNode = reactFlowInstance.getNode(params.source);
      const targetNode = reactFlowInstance.getNode(params.target);
  
      // Custom logic to determine edge type or style (optional)
      const isTargetLeftOfSource = targetNode?.position.x < sourceNode?.position.x;
      const edgeType = isTargetLeftOfSource ? 'loopback' : 'default';
  
      const newEdge = {
        id: cleanEdgeId(uuidv4()), // use UUID without `reactflow__edge-` prefix
        source: params.source,
        target: params.target,
        sourceHandle: params.sourceHandle, // Store the source handle ID
        targetHandle: params.targetHandle, // Store the target handle ID
        type: edgeType,
        data: {
          sourcePosition: sourceNode?.position,
          targetPosition: targetNode?.position,
        },
      };
  
      setEdges((eds) => addEdge(newEdge, eds));
    },
    [reactFlowInstance, setEdges]
  );
  
  
  
  

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow') || 'standard'; // Default type if undefined
    const newNodeType = getNodeType(type); // Get node type, fallback to StandardNode if not found

    // Generate a unique ID for the new node
    const newNodeId = `node-${idCounter.current}`;
    idCounter.current += 1;

    // Calculate position where the node should be placed
    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    const newNode = {
      id: newNodeId,
      type, // Keep the original node type (e.g., table_read)
      position,
      data: { label: `${type} node`, id: newNodeId, conditions: [] }, // Initialize data and conditions
    };

    setNodes((nds) => nds.concat(newNode));
  };
  


  const handleSaveFlow = async () => {
    if (!flowName.trim()) {
      alert('Flow name is required');
      return;
    }
    try {
      const updatedParameters = parameters.map(({ flow_parameter_id, ...rest }) => rest);
      console.log("handleSaveFlow - updatedParameters: "+JSON.stringify(updatedParameters))
      if (currentFlow && !isNewFlow) {
        const resultAction = await dispatch(updateFlow({
          flow_id: Number(itemId),
          flow_name: flowName,
          flow_nodes: nodes,
          flow_edges: edges,
          flow_parameters: updatedParameters,
        }));
        unwrapResult(resultAction); // Ensure action was successful
      } else {
        const resultAction = await dispatch(createFlow({
          flow_name: flowName,
          flow_nodes: nodes,
          flow_edges: edges,
          flow_parameters: updatedParameters,
        }));
        const newFlow = unwrapResult(resultAction); // Ensure action was successful
        navigate(`/apps/scheduler/editFlow/${newFlow.flow_id}`); // Redirect to the newly created flow
      }
    } catch (err) {
      alert(err.message); // Show popup for invalid JSON
    }
  };
  
  
  
  
  
  

  const renderSidebarContent = () => {
    if (!selectedNode) return null;

    const commonFields = (
      <>
        <Form.Group controlId="formNodeLabel">
          <Form.Label>Node Label</Form.Label>
          <Form.Control
            type="text"
            value={selectedNode.data.label || ''}
            onChange={(e) => handleInputChange('label', e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formNodeContinueOnError" className="d-flex align-items-center">
          <Form.Check
            type="checkbox"
            label="Continue on Error"
            inline
            checked={selectedNode.data.continueOnError === true}
            onChange={(e) => handleInputChange('continueOnError', e.target.checked)}
          />
        </Form.Group>

      </>
    );

    const conditionFields = selectedNode.data.conditions.map((condition, index) => (
      <div key={`condition-${index}`} className="d-flex align-items-center mt-2">
        <Form.Group controlId={`formConditionKey${index}`} className="me-2" style={{ flex: 1 }}>
          <Form.Label>{`Condition Key ${index + 1}`}</Form.Label>
          <Form.Control
            type="text"
            value={condition.key}
            onChange={(e) => handleInputChange('key', e.target.value, index)}
          />
        </Form.Group>
        <Form.Group controlId={`formConditionValue${index}`} className="me-2" style={{ flex: 1 }}>
          <Form.Label>{`Condition Value ${index + 1}`}</Form.Label>
          <Form.Control
            type="text"
            value={condition.value}
            onChange={(e) => handleInputChange('value', e.target.value, index)}
          />
        </Form.Group>
        <Button
          variant="danger"
          size="sm"
          className="mt-4"
          onClick={() => removeCondition(index)}
        >
          &minus;
        </Button>
      </div>
    ));

    switch (selectedNode.type) {
      case 'bash':
        return (
          <DynamicForm
            node={selectedNode}
            onChange={(field, value) => handleInputChange(field, value)}
          />
        );
      case 'transform':
        return (
          <>
            {commonFields}
            <Form.Group controlId="formNodeInputFile" className="mt-2">
              <Form.Label>Input File</Form.Label>
              <Form.Control
                type="text"
                value={selectedNode.data.inputFile || ''}
                onChange={(e) => handleInputChange('inputFile', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNodeOutputFilename" className="mt-2">
              <Form.Label>Output Filename</Form.Label>
              <Form.Control
                type="text"
                value={selectedNode.data.outputFilename || ''}
                onChange={(e) => handleInputChange('outputFilename', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNodeHeadersFromSource" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Headers from Source"
                checked={selectedNode.data.headersFromSource || false}
                onChange={(e) => handleInputChange('headersFromSource', e.target.checked)}
              />
            </Form.Group>
            <Form.Group controlId="formNodeCustomHeaders" className="mt-2">
              <Form.Label>Custom Headers</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={selectedNode.data.customHeaders || ''}
                onChange={(e) => handleInputChange('customHeaders', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNodeOutputTemplate" className="mt-2">
              <Form.Label>Output Template</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={selectedNode.data.outputTemplate || ''}
                onChange={(e) => handleInputChange('outputTemplate', e.target.value)}
              />
            </Form.Group>
          </>
        );
      case 'decision':
        return (
          <>
            {commonFields}
            {conditionFields}
            <Button variant="secondary" className="mt-3" onClick={addCondition}>
              Add Condition
            </Button>{' '}
          </>
        );
      default:
        return (
          <>
            <DynamicForm
              node={selectedNode}
              onChange={(field, value) => handleInputChange(field, value)}
            />
          </>
        );
    }
  };

  if (isDataLoading) {
    return (
      <Container fluid style={{ height: 'calc(100vh - 190px)' }}>
        <Row className="mb-3">
          <Col md={12} className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid style={{ height: 'calc(100vh - 190px)' }}>
      <Row className="mb-3">
        <Col md={10}>
          <Form>
            <Form.Group controlId="formFlowName">
              <Form.Label>Flow {t('name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter flow name"
                value={flowName}
                onChange={(e) => setFlowName(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Col>
        <Col md={2} className="d-flex align-items-end">
          <Button variant="primary" onClick={handleSaveFlow} style={{ width: '100%' }}>
            {t('button.save')}
          </Button>
        </Col>
      </Row>
      <Row className="flex-grow-1" style={{ height: 'calc(100% - 60px)' }}>
        <Col md={2} style={{ overflowY: 'auto', height: '100%' }}>
          <Sidebar
            onDragStart={(event, nodeType) => event.dataTransfer.setData('application/reactflow', nodeType)}
            parameters={parameters}
            setParameters={setParameters}
            flow_id={itemId}
          />
        </Col>
        <Col md={showSidebar ? 8 : 10} className="d-flex">
          <div id="reactflow-wrapper" className="flex-grow-1" style={{ height: '100%' }} onDrop={onDrop} onDragOver={onDragOver}>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeDoubleClick={onNodeDoubleClick}
                edgeTypes={edgeTypes}
                fitView
                nodeTypes={nodeTypes}
                proOptions={proOptions}
              >
                <Background />
                <Controls />
              </ReactFlow>
            )}
          </div>
        </Col>
        {showSidebar && (
          <Col md={2}>
            <div className="sidebar" style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
  <h5>Edit Node</h5>
  <Form>
    {renderSidebarContent()}
    <Button variant="primary" onClick={handleSave} className="mt-3">
      {t('button.save')}
    </Button>{' '}
    <Button variant="secondary" onClick={handleCloseSidebar} className="mt-3">
      {t('button.close')}
    </Button>
  </Form>
</div>

          </Col>
        )}
      </Row>
    </Container>
  );
};

const App = () => (
  <ReactFlowProvider>
    <FlowEditor />
  </ReactFlowProvider>
);

export default App;
