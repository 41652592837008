import React from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { v4 as uuidv4 } from 'uuid'; // Import UUID function

import iconSql from '../../../../../assets/img/icons/sql-database-generic.svg';
import iconSap from '../../../../../assets/img/icons/sap.svg';
import iconRest from '../../../../../assets/img/icons/rest.png';
import iconFile from '../../../../../assets/img/icons/file.png';

const icons = {
  sql: iconSql,
  sap_abap_run: iconSap,
  rest: iconRest,
  file: iconFile,
};

const Icon = ({ iconname }) => (
  <img
    src={iconname}
    alt="icon"
    style={{ maxWidth: '20px', height: '20px' }}
  />
);

const StandardNode = ({ data, type, id }) => {
  // console.log("StandardNode data: "+ JSON.stringify(data))
  const iconname = icons[type] || iconFile; // Select icon based on type
  const nodeClass = `dndnode sql status-${data.status || 'unknown'}`; // Construct dynamic class

  // Base height is the initial height of the node, without options
  const baseHeight = 28;
  const minHeight = 55; // Minimum height for the node

  // Find all fields that have options with hasHandle: true
  const handleFields = Object.keys(data)
    .filter((fieldName) => Array.isArray(data[fieldName])) // Ensure field is an array
    .map((fieldName) => ({
      fieldName,
      options: data[fieldName].filter((option) => option.hasHandle), // Filter for options with hasHandle: true
    }))
    .filter((field) => field.options.length > 0); // Only include fields with handles

  // Calculate the total height based on the number of handles/options
  const totalHeight =
    baseHeight + handleFields.reduce((acc, field) => acc + (field.options.length * 27), 0);

  // Render handles for options with hasHandle set to true
  const renderHandles = () => {
    if (handleFields.length === 0) {
      // Fallback to a standard handle if no options have hasHandle
      return (
        <Handle
          type="source"
          position={Position.Right}
          style={{
            top: '50%',
            backgroundColor: 'gray',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            transform: 'translateY(-50%)', // Center the handle vertically
          }}
        />
      );
    }

    let handleOffset = 1; // Track the offset for correct handle position

    return handleFields.flatMap((field) =>
      field.options.map((option, index) => {
        const currentTop = handleOffset * 27; // Calculate the top position of the handle
        handleOffset++; // Increment the offset for the next handle

        return (
          <div
            key={`${id}-${field.fieldName}-${index}`}
            style={{
              position: 'absolute',
              top: `${currentTop}px`,
              right: '0px',
              display: 'flex',
            }}
          >
            <Handle
              id={option.id} // Use existing ID or generate a new one
              type="source"
              position={Position.Right}
              style={{
                top: 0,
                backgroundColor: 'gray',
                width: '8px',
                height: '8px',
                borderRadius: '50%', // Circular handles
              }}
            />
            <span style={{ fontSize: '0.75em', marginLeft: '10px' }}>{option.key}</span>
            
          </div>
        );
      })
    );
  };

  return (
    <div className={nodeClass} style={{ height: `${Math.max(totalHeight, minHeight)}px` }}>
      <div>
        <Icon iconname={iconname} /> {data.label}
      </div>
      <div style={{ fontSize: '0.75em' }}>ID: {id}</div>
      <Handle type="target" position={Position.Left} /> {/* Left handle always visible */}
      {renderHandles()} {/* Right handles for fields with hasHandle */}
    </div>
  );
};

StandardNode.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default StandardNode;
