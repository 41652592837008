import React from "react";

const SubchainNode = ({ id, data, allNodes, setNodes, updateNode }) => {
  const isExpanded = data.isExpanded || false;

  // Calculate the bounding box to include all visible child nodes
  const calculateDimensions = (visibleChildren) => {
    const validChildren = visibleChildren.filter((n) => n.position);
  
    if (validChildren.length === 0) {
      console.log(`No valid child nodes with positions. Default size: { width: 200, height: 100 }`);
      return { width: 200, height: 100 };
    }
  
    const xPositions = validChildren.map((n) => n.position?.x || 0);
    const yPositions = validChildren.map((n) => n.position?.y || 0);
    const widths = validChildren.map((n) => n.width || 150);
    const heights = validChildren.map((n) => n.height || 50);
  
    const minX = Math.min(...xPositions);
    const minY = Math.min(...yPositions);
    const maxX = Math.max(...xPositions.map((x, i) => x + widths[i]));
    const maxY = Math.max(...yPositions.map((y, i) => y + heights[i]));
  
    const dimensions = {
      width: maxX - minX + 60, // Add padding
      height: maxY - minY + 60, // Add padding
    };
  
    console.log(`Calculated dimensions for parent ${id}:`, dimensions);
    return dimensions;
  };
  
  

  // Handle expand/collapse and propagate changes

  const handleToggle = () => {
    const visibleChildren = allNodes.filter(
      (node) => node.parentId === id && !node.hidden
    );

    const newDimensions = isExpanded
      ? { width: 200, height: 100 }
      : calculateDimensions(visibleChildren);

    // Update parent node dimensions
    updateNode({
      id,
      width: newDimensions.width,
      height: newDimensions.height,
      data: { ...data, isExpanded: !isExpanded },
    });

    // Update child node visibility
    allNodes.forEach((node) => {
      if (node.parentId === id) {
        updateNode({ ...node, hidden: !isExpanded });
      }
    });
  };
  

  return (
    <div
      style={{
        width: data.width || 200, // Reflect dynamic width
        height: data.height || 100, // Reflect dynamic height
        border: "2px solid black",
        borderRadius: "8px",
        backgroundColor: isExpanded ? "#c8e6c9" : "#f8bbd0",
        textAlign: "center",
        position: "relative",
        boxSizing: "border-box",
        transition: "all 0.3s ease",
      }}
    >
      <button
        onClick={handleToggle}
        style={{
          backgroundColor: isExpanded ? "#388e3c" : "#d32f2f",
          color: "white",
          border: "none",
          borderRadius: "4px",
          padding: "5px 10px",
          cursor: "pointer",
          marginTop: "10px",
        }}
      >
        {isExpanded ? "-" : "+"}
      </button>
      <strong>{data.label || "Subchain Node"}</strong>
    </div>
  );
};

export default SubchainNode;
