import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from 'services/api';

interface Parameter {
  name: string;
  type: string;
  default_value: string;
  mandatory: boolean | null;
  runtime: boolean;
  visible: boolean;
}

interface ParameterValue {
  name: string;
  value: any;
}

interface ScheduleDetails {
  worker_name: string;
  scheduled_datetime: string;
  scheduled_timezone: string;
  recurrence: {
    frequency: string;
    interval: number;
    days_of_week?: number[];
    days_of_month?: number[];
  };
}

interface FlowSubmitScreenProps {
  showModal: boolean;
  handleClose: () => void;
  handleSubmit: (scheduleDetails: ScheduleDetails, parameters: ParameterValue[]) => void;
  currentFlowId: number | null; // Pass currentFlowId from the parent
}

const timeWindows = [
  'Weekday Business Hours (08:00 AM - 06:00 PM, Mon-Fri)',
  'Nightly Batch Processing (12:00 AM - 04:00 AM)',
  'End-of-Day Reports (06:00 PM - 07:00 PM)',
  'Weekend Maintenance (Saturday 12:00 AM - Sunday 06:00 AM)',
  'Month-End Processing (Last Day of Month 08:00 PM - 12:00 AM)',
  'Quarterly Financial Runs (1st of Quarter 02:00 AM - 05:00 AM)',
  'Weekly Backup Window (Sunday 02:00 AM - 04:00 AM)',
  'Daily Data Sync (03:00 AM - 05:00 AM)',
  'Morning Data Loads (06:00 AM - 08:00 AM)',
  'Nightly System Updates (11:00 PM - 01:00 AM)',
  // Add more time windows as needed
];


const FlowSubmitScreen: React.FC<FlowSubmitScreenProps> = ({ showModal, handleClose, handleSubmit, currentFlowId }) => {
  const { t } = useTranslation();
  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails>({
    worker_name: '',
    scheduled_datetime: '',
    scheduled_timezone: '',
    recurrence: { frequency: 'none', interval: 1, days_of_week: [], days_of_month: [] },
  });
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [parameterValues, setParameterValues] = useState<ParameterValue[]>([]);
  const [loading, setLoading] = useState(true);

  const [timezones, setTimezones] = useState<{ key: string; value: string }[]>([]);

  useEffect(() => {
    const fetchTimezones = () => {
      const timezonesWithOffsets: { key: string; value: string }[] = Intl.supportedValuesOf('timeZone').map((tz) => {
        const date = new Date();
        const formatter = new Intl.DateTimeFormat('en-US', {
          timeZone: tz,
          timeZoneName: 'shortOffset',
        });
        const parts = formatter.formatToParts(date);
        const offset = parts.find((part) => part.type === 'timeZoneName')?.value || '';
        return { key: tz, value: `${offset} - ${tz}` };
      });

      // Sort by offset
      timezonesWithOffsets.sort((a, b) => {
        const offsetA = parseFloat(a.value.split(' ')[0].replace('UTC', '').replace(':', '.'));
        const offsetB = parseFloat(b.value.split(' ')[0].replace('UTC', '').replace(':', '.'));
        return offsetA - offsetB;
      });

      setTimezones(timezonesWithOffsets);

      // Set default timezone
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setScheduleDetails((prev) => ({
        ...prev,
        scheduled_timezone: userTimeZone,
      }));
    };

    fetchTimezones();
  }, []);

  useEffect(() => {
    const fetchFlowDetails = async () => {
      if (!currentFlowId) return;

      try {
        setLoading(true);
        const response = await api.get(`/flows/${currentFlowId}`);
        const flowDetails = response.data;

        // Filter visible parameters and initialize parameter values
        const visibleParameters = flowDetails.flow_parameters.filter((param: Parameter) => param.visible);
        setParameters(visibleParameters);

        const initialValues = visibleParameters.map((param: Parameter) => ({
          name: param.name,
          value: param.default_value || '', // Initialize with default values
        }));
        setParameterValues(initialValues);
      } catch (error) {
        console.error('Failed to fetch flow details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (showModal) {
      fetchFlowDetails(); // Fetch details only when the modal is shown
    }
  }, [currentFlowId, showModal]);

  const validateJson = (value: string): boolean => {
    try {
      JSON.parse(value);
      return true;
    } catch {
      return false;
    }
  };

  const onParameterChange = (name: string, value: any) => {
    setParameterValues((prev) =>
      prev.map((param) =>
        param.name === name
          ? {
              ...param,
              value:
                parameters.find((p) => p.name === name)?.type === 'json'
                  ? value // Keep the raw input for JSON fields
                  : value === '' // Handle non-JSON
                  ? null
                  : value,
            }
          : param
      )
    );
  };
  
  
  

  const onSubmit = () => {
    const updatedParameters = parameterValues.map((param) => {
      const original = parameters.find((p) => p.name === param.name);
  
      // For JSON type, try to parse it or keep null
      if (original?.type === 'json') {
        return {
          name: param.name,
          value: validateJson(param.value) ? JSON.parse(param.value) : null,
        };
      }
  
      // For non-JSON types, directly return the value
      return {
        name: param.name,
        value: param.value !== original?.default_value ? param.value : null, // Include only changed values
      };
    });
  
    handleSubmit(scheduleDetails, updatedParameters);
    handleClose();
  };
  

  if (loading) {
    return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <div>{t('loading')}</div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Flow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Existing form fields */}
          <Form.Group controlId="worker_name">
            <Form.Label>Worker</Form.Label>
            <Form.Control
              type="text"
              value={scheduleDetails.worker_name}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, worker_name: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="scheduled_datetime">
            <Form.Label>{t('scheduled-datetime')}</Form.Label>
            <Form.Control
              type="datetime-local"
              value={scheduleDetails.scheduled_datetime}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, scheduled_datetime: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="scheduled_timezone">
            <Form.Label>{t('timezone')}</Form.Label>
            <Form.Control
              as="select"
              value={scheduleDetails.scheduled_timezone}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, scheduled_timezone: e.target.value })}
            >
              <option value="">{t('select-timezone')}</option>
              {timezones.map((timezone) => (
                <option key={timezone.key} value={timezone.key}>
                  {timezone.value}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Additional fields (e.g., time windows) */}
          <Form.Group controlId="time_window">
            <Form.Label>{t('select-window')}</Form.Label>
            <Form.Control
              as="select"
              value={scheduleDetails.scheduled_timezone}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, scheduled_timezone: e.target.value })}
            >
              <option value="">{t('select-time-window')}</option>
              {timeWindows.map((window, index) => (
                <option key={index} value={window}>
                  {window}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Parameters Section */}
          <h5>{t('parameters')}</h5>
          {parameterValues.map((param) => (
            <Form.Group key={param.name} controlId={`parameter-${param.name}`}>
              <Form.Label>
                {param.name} {parameters.find((p) => p.name === param.name)?.mandatory && '*'}
              </Form.Label>
              <Form.Control
                type={parameters.find((p) => p.name === param.name)?.type === 'json' ? 'textarea' : 'text'}
                as={parameters.find((p) => p.name === param.name)?.type === 'json' ? 'textarea' : undefined}
                rows={parameters.find((p) => p.name === param.name)?.type === 'json' ? 3 : undefined}
                value={param.value || ''}
                onChange={(e) => onParameterChange(param.name, e.target.value)}
                required={parameters.find((p) => p.name === param.name)?.mandatory || false}
                style={{
                  color:
                    parameters.find((p) => p.name === param.name)?.type === 'json' &&
                    !validateJson(param.value || '')
                      ? 'red'
                      : 'black', // Red text for invalid JSON
                }}
              />
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {t('schedule')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FlowSubmitScreen;
