import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import ReactFlow, {
  Controls,
  Background,
  ReactFlowProvider,
  Node,
} from 'reactflow';
import 'reactflow/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../scheduler/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFlowInstanceById, clearCurrentFlowInstance } from '../../../../../redux/slices/flowsSlice';

import FlowInstanceNodeContextMenu from './FlowInstanceNodeContextMenu';

import StartNode from '../../scheduler/node_types/StartNode';
import StandardNode from '../../scheduler/node_types/StandardNode';
import CustomNode from '../../scheduler/node_types/CustomNode';
import SQLNode from '../../scheduler/node_types/SQLNode';
import BASHNode from '../../scheduler/node_types/BASHNode';
import DecisionNode from '../../scheduler/node_types/DecisionNode';
import TransformNode from '../../scheduler/node_types/TransformNode';
import WorkflowNode from '../../scheduler/node_types/WorkflowNode';
import LoopbackEdge from '../../scheduler/edge_types/loopback';
import GroupNode from '../../scheduler/node_types/GroupNode';
import { sub } from 'date-fns';
import SubchainNode from '../../scheduler/node_types/SubchainNode';
import { useReactFlow } from 'reactflow';

const proOptions = { hideAttribution: true };

const edgeTypes = { loopback: LoopbackEdge };


interface RootState {
  flows: {
    currentFlowInstance: any;
    loading: boolean;
  };
}



// Helper functions for Base64 detection and decoding
const isBase64 = (str: string): boolean => {
  try {
    return btoa(atob(str)) === str;
  } catch (e) {
    return false;
  }
};

const decodeBase64 = (base64Content: string): string => {
  try {
    return atob(base64Content);
  } catch (e) {
    console.error('Failed to decode Base64 content:', e);
    return base64Content;
  }
};



const FlowInstanceViewer = () => {
  const { instanceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentFlowInstance = useSelector((state: RootState) => state.flows.currentFlowInstance);
  const loading = useSelector((state: RootState) => state.flows.loading);

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(''); // For modal content
  const [modalTitle, setModalTitle] = useState(''); // For modal title (file name)

  const [contextMenuNode, setContextMenuNode] = useState(null); // Node for context menu
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const reactFlowInstance = useReactFlow();

  const refreshLayout = () => {
    setTimeout(() => {
      reactFlowInstance.fitView(); // Ensure layout updates
    }, 50);
  };
  
  
    // Call refreshLayout after any node update
    useEffect(() => {
      // refreshLayout();
    }, [nodes]);
  
    const updateNode = (updatedNode) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) => (node.id === updatedNode.id ? updatedNode : node))
      );
    };
    
  
  
  
  useEffect(() => {
    if (instanceId) {
      dispatch(fetchFlowInstanceById(Number(instanceId)));
    } else {
      dispatch(clearCurrentFlowInstance());
    }
  }, [dispatch, instanceId]);

  useEffect(() => {
    if (currentFlowInstance) {
      const { flow_nodes, flow_edges } = currentFlowInstance;
  
      const mappedNodes = [];
      const childEdges = [];
  
      flow_nodes.forEach((node) => {
        const updatedData = {
          ...node.node_data,
          status: node.status,
          output: node.output,
          outputdetails: node.outputdetails,
        };
  
        // Push the parent node
        mappedNodes.push({
          id: node.node_id,
          type: node.type || 'default',
          position: { x: node.position_x, y: node.position_y },
          width: node.width || 200, // Add width for calculations
          height: node.height || 100, // Add height for calculations
          data: { ...updatedData, isExpanded: false },
          parentId: node.parentId || null,
        });
  
        // If the node is a Subchain, add child nodes and edges
        if (node.type === 'subchain' && node.child_nodes) {
          node.child_nodes.forEach((child) => {
            mappedNodes.push({
              id: child.node_id,
              type: child.type || 'default',
              position: { x: child.position_x, y: child.position_y },
              data: child.node_data,
              parentId: node.node_id, // Attach to parent
              hidden: true, // Default to hidden
            });
          });
  
          // Add child edges
          node.child_edges?.forEach((edge) => {
            childEdges.push({
              id: edge.edge_id,
              source: edge.source,
              target: edge.target,
              type: edge.type || 'default',
              sourceHandle: edge.source_handle,
              targetHandle: edge.target_handle,
            });
          });
        }
      });
  
      setNodes(mappedNodes);
      setEdges([...flow_edges, ...childEdges]);
    } else {
      setNodes([]);
      setEdges([]);
    }
  }, [currentFlowInstance]);
  
  
  const getChildren = (parentId, nodes = []) => {
    // Collect direct children from root nodes
    const directChildren = nodes.filter((node) => node.parentId === parentId);
  
    // Collect nested children from the `children` array of the matching node
    const nestedChildren = nodes
      .filter((node) => node.node_data?.id === parentId)
      .flatMap((node) => node.children || []);
  
    // Combine both direct and nested children
    return [...directChildren, ...nestedChildren];
  };
  
  

  const nodeTypes = {
    sql: StandardNode,
    bash: StandardNode,
    sap_abap_run: StandardNode,
    sendMail: StandardNode,
    decision: DecisionNode,
    tableRead: StandardNode,
    rest: StandardNode,
    workflow: WorkflowNode,
    workflowCreate: StandardNode,
    workflowActionCreate: StandardNode,
    group: GroupNode,
    subchain: (props) => (
      <SubchainNode
        {...props}
        setNodes={setNodes}
        allNodes={nodes}
        updateNode={updateNode} // Add this
      />
    ),
  };

  const onNodeDoubleClick = (event, node) => {
    setSelectedNode(node);
    setShowSidebar(true);
  };

  const onNodeContextMenu = (event, node) => {
    event.preventDefault(); // Prevent default browser context menu

    const container = document.getElementById('reactflow-wrapper');
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const relativeX = event.clientX - containerRect.left;
      const relativeY = event.clientY - containerRect.top;

      setContextMenuPosition({ x: relativeX, y: relativeY });
      setContextMenuNode(node);
    }
  };

  // Close the context menu when left-clicking anywhere on the canvas
  const onCanvasClick = (event) => {
    if (contextMenuNode) {
      setContextMenuNode(null); // Hide the context menu
    }
  };

  useEffect(() => {
    if (selectedNode) {
      console.log("Selected selectedNode: " + JSON.stringify(selectedNode));
    }
  }, [selectedNode]);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowModal = (title: string, content: string) => {
    const decodedContent = isBase64(content) ? decodeBase64(content) : content;
    setModalTitle(title);
    setModalContent(decodedContent);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleOpenDetails = (node) => {
    setSelectedNode(node);
    setShowSidebar(true);
    setContextMenuNode(null); // Hide the context menu
  };

  const handleDeleteNode = (node) => {
    setNodes((nds) => nds.filter((n) => n.id !== node.id));
    setContextMenuNode(null); // Hide the context menu
  };


  return (
    <Container fluid style={{ height: 'calc(100vh - 190px)' }}>
      <Row className="mb-3">
        <Col md={12} className="d-flex justify-content-between align-items-center">
          <h3>Flow Instance Viewer</h3>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Col>
      </Row>
      <Row className="flex-grow-1" style={{ height: 'calc(100% - 60px)' }}>
        <Col md={showSidebar ? 9 : 12} className="d-flex">
          <div
            id="reactflow-wrapper"
            className="flex-grow-1"
            style={{ height: '100%' }}
            onClick={onCanvasClick} // Detect canvas clicks to hide context menu
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                proOptions={proOptions}
                onNodeDoubleClick={onNodeDoubleClick}
                onNodeContextMenu={onNodeContextMenu} // Right-click for context menu
              >
                <Background />
                <Controls />
              </ReactFlow>
            )}
          </div>
        </Col>
        {showSidebar && (
          <Col md={3} className="sidebar">
            <div>
              <h5>Node Details</h5>
              {selectedNode && (
                <Form>
                  <Form.Group controlId="formNodeLabel">
                    <Form.Label>Node Label</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedNode.data.label || ''}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group controlId="formNodeStatus" className="mt-2">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedNode.data.status || ''}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group controlId="formNodeOutput" className="mt-2">
                    <Form.Label>Output</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={JSON.stringify(selectedNode.data.output) || ''}
                      readOnly
                      onClick={() => handleShowModal('Output', JSON.stringify(selectedNode.data.output))}
                    />
                  </Form.Group>

                  {/* Display outputDetailsBash with file names and modal on click */}
                  {selectedNode.data.outputdetails && selectedNode.data.outputdetails.files && (
                    <Form.Group controlId="formNodeOutputDetails" className="mt-2">
                      <Form.Label>Output Details (Files)</Form.Label>
                      <ul>
                        {selectedNode.data.outputdetails.files.map((file, index) => (
                          <li key={index}>
                            <Button variant="link" onClick={() => handleShowModal(file.filename, file.content)}>
                              {file.filename}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </Form.Group>
                  )}

                  <Button variant="secondary" onClick={handleCloseSidebar} className="mt-3">
                    Close
                  </Button>
                </Form>
              )}
            </div>
          </Col>
        )}
      </Row>

      {/* Context Menu */}
      {contextMenuNode && (
        <FlowInstanceNodeContextMenu
          menuPosition={contextMenuPosition}
          selectedNode={contextMenuNode}
          onOpenDetails={handleOpenDetails}
          onDeleteNode={handleDeleteNode}
          onClose={() => setContextMenuNode(null)}
        />
      )}

      <Modal show={showModal} onHide={handleCloseModal} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{modalContent}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const App = () => (
  <ReactFlowProvider>
    <FlowInstanceViewer />
  </ReactFlowProvider>
);

export default App;